import { Routes, Route } from "react-router-dom";
import { connect } from "react-redux";
import UserContext from "../utils/userContext";
import * as settingsActions from "../../hooks/actions";
// Landing
import Home from "../../pages/landing/home";
import About from "../../pages/landing/about";
import Blog from "../../pages/landing/blog/blog";
import BlogSoon from "../../pages/landing/blog/blog_soon";
import BlogDetails from "../../pages/landing/blog/posts/_blog_details";
import PostSellerSprite from "../../pages/landing/blog/posts/sellersprite_promo";
import Support from "../../pages/landing/support/support";
import Pricing from "../../pages/landing/pricing";
import Notifications from "../../shared/notifications/notifications";
import Terms from "../../pages/landing/terms";
import Privacy from "../../pages/landing/privacy";
import HowItWorks from "../../pages/landing/how-it-works";
import Consulting from "../../pages/landing/consulting";
import Warehousing from "../../pages/landing/warehousing";

// Articles
import UpdateExtension from "../../pages/landing/articles/how-to-update-chrome-extensions";

// Auth
import Login from "../../pages/auth/login";
import Register from "../../pages/auth/register";
import Forgot from "../../pages/auth/forgot";

// Client Dashboard
import DashboardClient from "../../pages/dashboard_client/dashboard_client";
import CompareMarkets from "../../pages/dashboard_client/compare_markets/compare_markets";
import Affilliate from "../../pages/dashboard_client/affilliate/affilliate";
import Favorites from "../../pages/dashboard_client/favorites/favorites";
import Tickets from "../../pages/dashboard_client/tickets/tickets";
import CurrentPlan from "../../pages/dashboard_client/plan/plan";
import Asin360 from "../../pages/dashboard_client/asin360/saved_asins/asins";
import PBI from "../../pages/dashboard_client/asin360/product_based_info/pbi";
import PBIManualPage from "../../pages/dashboard_client/asin360/product_based_info/pbi_manual";

import KeywordTool from "../../pages/dashboard_client/keyword_tool/tool";
import BarcodeReaderWelcome from "../../pages/dashboard_client/barcode_reader/1_barcode_reader_welcome";
import BarcodeReaderForm from "../../pages/dashboard_client/barcode_reader/2_barcode_reader_form";
import BarcodeReaderProducts from "../../pages/dashboard_client/barcode_reader/3_barcode_reader_products";
import History from "../../pages/dashboard_client/history/history";
import Tracking from "../../pages/dashboard_client/tracking/tracking";

// Admin Dashboard
import DashboardAdmin from "../../pages/dashboard_admin/dashboard_admin";
import Clients from "../../pages/dashboard_admin/clients/clients";
import Balance from "../../pages/dashboard_admin/balance/balance";
import Discovery from "../../pages/dashboard_admin/discovery/discovery";
import Affiliates from "../../pages/dashboard_admin/affiliates/affiliates";
import TicketAdmin from "../../pages/dashboard_admin/tickets/tickets";
import SupportAdmin from "../../pages/dashboard_admin/support/supports";
import Reports from "../../pages/dashboard_admin/reports/reports";
import AsinLists from "../../pages/dashboard_admin/asin_lists/asinLists";
import Users from "../../pages/dashboard_admin/users/users";
import Tiers from "../../pages/dashboard_admin/tiers/tiers";
import Sellers from "../../pages/dashboard_admin/sellers/sellers";
import Email from "../../pages/dashboard_admin/email/email";

// Shared
import SettingsPersonal from "../../shared/settings/personal";
import SettingsSecurity from "../../shared/settings/security";
import SettingsNotification from "../../shared/settings/notifications";
import MarketSettings from "../../shared/settings/markets/markets";
import Integrations from "../../shared/settings/integrations";

// Checkout Complete
import Checkout from "../payment/checkout";
import Versions from "../../pages/dashboard_admin/versions/versions";
import { useContext, useState } from "react";
import { useEffect } from "react";

// Promo
import Raffle from "../../pages/promo/raffle";
import PromoRegister from "../../pages/promo/register";

const Layout = ({
  isLogged,
  setLogged,
  setPage,
  isPage,
  isPaymentDrawer,
  setPaymentDrawer,
  isBuyTokenDrawer,
  setBuyTokenDrawer,
  setUserDetailsDrawer,
  paymentData,
  setPaymentData,
  selectedUser,
  setSelectedUser,
}) => {
  const [loggedInUser, setLoggedInUser] = useState(useContext(UserContext));
  const [isAdmin, setIsAdmin] = useState(false);
  useEffect(() => {
    console.log(loggedInUser);
    if (loggedInUser) {
      setLogged(true);
      if (loggedInUser?.role === "admin") {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    } else {
      setLogged(false);
    }
  }, [loggedInUser]);
  console.log("loggedInUser", loggedInUser);

  const adminRoutes = [
    <Route
      key="/admin-dashboard"
      path="/admin-dashboard"
      element={<DashboardAdmin setPage={setPage} />}
    />,
    <Route
      key="/clients"
      path="/clients"
      element={
        <Clients
          setPage={setPage}
          setUserDetailsDrawer={setUserDetailsDrawer}
        />
      }
    />,
    <Route
      key="/balance"
      path="/balance"
      element={<Balance setPage={setPage} />}
    />,
    <Route
      key="/admin-support"
      path="/admin-support"
      element={
        <SupportAdmin
          setPage={setPage}
          setUserDetailsDrawer={setUserDetailsDrawer}
        />
      }
    />,
    <Route
      key="/admin-reports"
      path="/admin-reports"
      element={
        <Reports
          setPage={setPage}
          setUserDetailsDrawer={setUserDetailsDrawer}
        />
      }
    />,
    <Route
      key="/admin-asin-lists"
      path="/admin-asin-lists"
      element={<AsinLists setPage={setPage} />}
    />,
    <Route
      key="/admin-tiers"
      path="/admin-tiers"
      element={<Tiers setPage={setPage} />}
    />,
    <Route
      key="/admin-sellers"
      path="/admin-sellers"
      element={<Sellers setPage={setPage} />}
    />,
    <Route
      key="/admin-email"
      path="/admin-email"
      element={<Email setPage={setPage} />}
    />,

    <Route
      key="/admin-tickets"
      path="/admin-tickets"
      element={
        <TicketAdmin
          setPage={setPage}
          setUserDetailsDrawer={setUserDetailsDrawer}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
        />
      }
    />,
    <Route
      key="/discovery"
      path="/discovery"
      element={
        <Discovery
          setPage={setPage}
          setUserDetailsDrawer={setUserDetailsDrawer}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
        />
      }
    />,
    <Route
      key="/admin-affiliates"
      path="/admin-affiliates"
      element={
        <Affiliates
          setPage={setPage}
          setUserDetailsDrawer={setUserDetailsDrawer}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
        />
      }
    />,
    <Route
      key="/users"
      path="/users"
      element={
        <Users
          setPage={setPage}
          setUserDetailsDrawer={setUserDetailsDrawer}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
        />
      }
    />,
    <Route
      key="/versions"
      path="/versions"
      element={<Versions setPage={setPage} />}
    />,
    <Route
      key="/raffle"
      path="/raffle"
      element={<Raffle setPage={setPage} />}
    />,
  ];

  const clientRoutes = [
    <Route
      key="/client-dashboard"
      path="/client-dashboard"
      element={
        <DashboardClient
          setPage={setPage}
          isBuyTokenDrawer={isBuyTokenDrawer}
          setBuyTokenDrawer={setBuyTokenDrawer}
          paymentData={paymentData}
          setPaymentData={setPaymentData}
        />
      }
    />,
    <Route
      key="/my-plan"
      path="/my-plan"
      element={<CurrentPlan setPage={setPage} />}
    />,
    <Route
      key="/tickets"
      path="/tickets"
      element={<Tickets setPage={setPage} />}
    />,
    <Route
      key="/affilliate"
      path="/affilliate"
      element={<Affilliate setPage={setPage} />}
    />,
    <Route
      key="/asin360"
      path="/asin360"
      element={<Asin360 setPage={setPage} />}
    />,
    <Route
      key="/product-based-info/:asin"
      path="/product-based-info/:asin"
      element={<PBI setPage={setPage} setBuyTokenDrawer={setBuyTokenDrawer} />}
    />,
    <Route
      key="/product-based-info-manual/:asin"
      path="/product-based-info-manual/:asin"
      element={
        <PBIManualPage
          setPage={setPage}
          setBuyTokenDrawer={setBuyTokenDrawer}
        />
      }
    />,
    <Route
      key="/keyword-tool"
      path="/keyword-tool"
      element={<KeywordTool setPage={setPage} />}
    />,
    <Route
      key="/barcode-reader-welcome"
      path="/barcode-reader-welcome"
      element={<BarcodeReaderWelcome setPage={setPage} />}
    />,
    <Route
      key="/barcode-reader-form"
      path="/barcode-reader-form"
      element={<BarcodeReaderForm setPage={setPage} />}
    />,
    <Route
      key="/barcode-reader-products/:barcode"
      path="/barcode-reader-products/:barcode"
      element={<BarcodeReaderProducts setPage={setPage} />}
    />,
    <Route
      key="/favorites"
      path="/favorites"
      element={<Favorites setPage={setPage} />}
    />,
    <Route
      key="/history"
      path="/history"
      element={<History setPage={setPage} />}
    />,
    <Route
      key="/tracking"
      path="/tracking"
      element={<Tracking setPage={setPage} />}
    />,
  ];

  const sharedLoggedInRequiredPages = [
    <Route
      key="/personal-settings"
      path="/personal-settings"
      element={<SettingsPersonal setPage={setPage} isPage={isPage} />}
    />,
    <Route
      key="/security-settings"
      path="/security-settings"
      element={<SettingsSecurity setPage={setPage} isPage={isPage} />}
    />,
    <Route
      key="/integration-settings"
      path="/integration-settings"
      element={<Integrations setPage={setPage} isPage={isPage} />}
    />,
    <Route
      key="/marketplace-settings"
      path="/marketplace-settings"
      element={<MarketSettings setPage={setPage} isPage={isPage} />}
    />,
    <Route
      key="/checkout"
      path="/checkout"
      element={
        <Checkout setPage={setPage} isPage={isPage} paymentData={paymentData} />
      }
    />,
  ];
  const publicRoutes = [
    <Route key="/404" path="*" element={<Home setPage={setPage} />} />,

    <Route key="/home" path="/" element={<Home setPage={setPage} />} />,
    <Route key="/about" path="/about" element={<About setPage={setPage} />} />,
    <Route key="/blog" path="/blog" element={<BlogSoon setPage={setPage} />} />,

    <Route
      key="/blog-details"
      path="/blog-details"
      element={<BlogDetails setPage={setPage} />}
    />,
    <Route
      key="/boost-amazon-sales-sellersprite-kepicker"
      path="/boost-amazon-sales-sellersprite-kepicker"
      element={<PostSellerSprite setPage={setPage} />}
    />,

    <Route
      key="/support"
      path="/support"
      element={<Support setPage={setPage} />}
    />,
    <Route
      key="/pricing"
      path="/pricing"
      element={
        <Pricing
          setPage={setPage}
          isPaymentDrawer={isPaymentDrawer}
          setPaymentDrawer={setPaymentDrawer}
          paymentData={paymentData}
          setPaymentData={setPaymentData}
        />
      }
    />,
    <Route
      key="/notifications"
      path="/notifications"
      element={<Notifications setPage={setPage} />}
    />,
    <Route key="/terms" path="/terms" element={<Terms setPage={setPage} />} />,
    <Route
      key="/privacy-policy"
      path="/privacy-policy"
      element={<Privacy setPage={setPage} />}
    />,
    <Route
      key="/how-it-works"
      path="/how-it-works"
      element={<HowItWorks setPage={setPage} />}
    />,
    <Route
      key="/how-to-update-chrome-extensions"
      path="/how-to-update-chrome-extensions"
      element={<UpdateExtension setPage={setPage} />}
    />,
    <Route key="/login" path="/login" element={<Login setPage={setPage} />} />,
    <Route
      key="/signup"
      path="/signup"
      element={<Register setPage={setPage} />}
    />,
    <Route
      key="/forgot"
      path="/forgot"
      element={<Forgot setPage={setPage} />}
    />,
    <Route
      key="/promo/:code"
      path="/promo/:code"
      element={<PromoRegister setPage={setPage} />}
    />,
    <Route
      key="/warehousing"
      path="/warehousing"
      element={<Warehousing setPage={setPage} />}
    />,
    <Route
      key="/consulting"
      path="/consulting"
      element={<Consulting setPage={setPage} />}
    />,
  ];
  return (
    <>
      <Routes>
        {/* public routes will always be rendered */}
        {publicRoutes}
        {/* if user is logged in, render the following routes */}
        {isLogged && (
          <>
            {sharedLoggedInRequiredPages}
            {clientRoutes}
          </>
        )}
        {/* if user is admin, render the following routes */}
        {isLogged && isAdmin && adminRoutes}
      </Routes>
    </>
  );
};

const mapStateToProps = (state) => ({
  isLogged: state.settings.isLogged,
});
const mapDispatchToProps = (dispatch) => ({
  setLogged: (e) => dispatch(settingsActions.setLogged(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
